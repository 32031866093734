import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Upload, message, Button } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import * as userService from "services/userService";

function ResumeUpload() {
  const { Dragger } = Upload;
  const [uploading, setUploading] = useState(false);
  const [uploaded, setUploaded] = useState(false);
  const [fileList, setFileList] = useState<any>([]);

  async function handleUpload() {
    const formData = new FormData();
    formData.append("file", fileList[0]);

    setUploading(true);
    setUploaded(false);

    try {
      const uploadedResumeResponse = await userService.uploadResume(formData);
      const uploadedResume = uploadedResumeResponse.data.data;
      if (uploadedResume) {
        message.success("Your resume is uploaded successfully.");
        setUploading(false);
        setUploaded(true);
      }
    } catch (error) {
      console.log(error);
      message.error("Upload failed. Please try again.");
      setUploading(false);
      setUploaded(false);
    }
  }

  const props = {
    onRemove: (file: any) => {
      setFileList(() => {
        const index = fileList.indexOf(file);
        const newFileList = fileList.slice();
        newFileList.splice(index, 1);
        return newFileList;
      });
    },
    beforeUpload: (file: any) => {
      setFileList([...fileList, file]);
      return false;
    },
    onChange: (changes: any) => {
      const file = changes.file;
      const isDocxOrPdf = file.type === "application/pdf" || file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
      if (!isDocxOrPdf) {
        message.error('Please upload a docx/pdf file.');
      }
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        message.error('Uploaded file must smaller than 5MB.');
      }

      if (!isDocxOrPdf || !isLt5M) {
        setFileList([]);
        return;
      }
      
      // Only to show one most recent uploaded files, and old ones will be replaced by the new    
      setFileList(() => {
        let newFileList = [changes.file];
        newFileList = newFileList.slice(-1);
        return newFileList;
      });
    },
    multiple: false,
    fileList,
  };

  return (
    <div className="main-container">
      <div className="text-center mb-2">
        <span className="text-xl font-semibold bg-green-500 py-1 px-3 rounded-full text-white">OR</span>
      </div>
      <div className="flex">
        <div className="flex-1 pr-10 border-dashed border-r-2 border-gray-400 pb-6">
          <div className="header-container">
            <h1 className="text-2xl font-semibold mb-0">Upload Your Resume</h1>
            <p>Please upload your resume and we will get your skills for you.</p>
          </div>
          <div className="resume-upload-container">

            <Dragger {...props}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">Click or drag file to this area to upload</p>
              <p className="ant-upload-hint">Please select a document with a ".docx" or ".pdf" extension.</p>
            </Dragger>

            { uploaded ? (
              <Button type="primary" shape="round" className="mt-4">
                <Link to={"/review-skills"}>Next</Link>
              </Button>
            ) : (
              <Button
                type="primary"
                shape="round"
                onClick={handleUpload}
                disabled={fileList.length === 0}
                loading={uploading}
                className="mt-4"
              >
                {uploading ? "Uploading" : "Upload Resume"}
              </Button>
            )}
          </div>
        </div>
        <div className="flex-1 pl-10 pb-6">
          <div className="header-container">
            <h1 className="text-2xl font-semibold mb-0">Enter Your Skills Manually</h1>
            <p>Click the enter skills button below to manually enter your skills.</p>
            <Button type="primary" shape="round">
              <Link to={"/review-skills"}>Enter Skills</Link>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResumeUpload;
