import axios from "axios";

const v1 = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE}/v1` || "",
  withCredentials: true,
  headers: { "X-Requested-With": "XMLHttpRequest" },
});

export { v1 as API };
export default v1;
