import React, { useEffect, useState } from "react";

import { Alert } from "antd";
import { AxiosResponse, AxiosError } from "axios";

import useIsMounted from "hooks/useIsMounted";
import { Skill } from "components/SkillsSelector";
import Form from "./Form";
import * as userService from "services/userService";
import { useHistory } from "react-router-dom";
import { useAuth } from "context/authContext";

// Transform skill to better consumable format
function formatSkills(skills: any) {
  return skills.map((item: any) => ({
    id: item.skill[0].id,
    name: item.skill[0].name,
  }));
}

type FormStatus =
  | {
      type: "success" | "error";
      message: string;
    }
  | undefined;

export default function ReviewSkills() {
  const isMounted = useIsMounted();
  const [loading, setLoading] = useState(true);
  const [skills, setSkills] = useState<Skill[]>([]);
  const [status, setStatus] = useState<FormStatus>();
  const history = useHistory();
  const {user, setUser} = useAuth();

  useEffect(() => {
    userService
      .getUserSkills()
      .then((response: AxiosResponse) => {
        if (isMounted.current) {
          const userSkills = response.data.data;
          setSkills(formatSkills(userSkills));
        }
      })
      .catch((error: AxiosError) => console.log(error))
      .finally(() => {
        if (isMounted.current) {
          setLoading(false);
        }
      });
  }, [isMounted]);

  async function handleSubmit(data: any) {
    try {
      const payload: { id: string }[] = Object.values(data);
      const updateSkillsResponse = await userService.updateUserSkills(payload);
      const updatedSkills = updateSkillsResponse.data.data;
      if (isMounted.current) {
        setSkills(formatSkills(updatedSkills));
        setStatus({ message: "Skills updated.", type: "success" });
        const userObj = user;
        if (userObj) {
          userObj.has_skills = true;
          setUser(userObj);
        }        
        history.push("/jobs");
      }
    } catch (error) {
      console.log(error);
      if (isMounted.current) {
        setStatus({ message: "Error updating skills.", type: "error" });
      }
    } finally {
      // Had to do this because somewhere in the parent tree, the container
      // is using overflow-x: hidden
      document
        .querySelector(".ant-layout-has-sider > .ant-layout")
        ?.scrollTo(0, 0);
    }
  }

  return (
    <div className="mb-8">
      <h1 className="text-2xl font-semibold">Review Your Skills</h1>
      {status && (
        <div className="mb-4">
          <Alert message={status.message} type={status.type} />
        </div>
      )}
      {loading ? (
        <div>Loading ... </div>
      ) : (
        <Form initialValues={skills} submit={handleSubmit} />
      )}
    </div>
  );
}
