import React from "react";
import WaveBg from "../resources/images/wave-bg.svg";

function Home() {
  return (
    <div className="homepage-container w-full border border-gray-300 border-solid">
      <div className="billboard">
        <div className="board-container mx-auto w-2/3">
          <div className="home-banner-container">
            <div className="inline-block w-1/2">
              <span className="text-white text-4xl font-bold">
                Upload your resume and we will interview you.              
              </span>
              <a className="inline-block text-lg hover:bg-orange-100 px-5 py-3 hover:text-orange-500 font-bold rounded-full cursor-pointer shadow-lg" href="/register">Start Practicing for FREE</a>
            </div>      
          </div>
        </div>
        <div className="relative">
          <img src={WaveBg} alt="Wave" />
        </div>
      </div>
      <div className="section-container text-left mt-20">
        <div className="flex w-2/3 mx-auto">
        <div className="section-img-container-lg win-bg"></div>
          <div className="flex-1 flex flex-col justify-center">
            <span className="text-4xl font-bold">GIVE US A TRY</span>
            <p>
              Become an interview rockstar! If lack of confidence and skills gets in your way during job interviews, we've got the solution.
            </p>
          </div>
        </div>
      </div>
      <div className="section-container text-left">
        <div className="flex w-2/3 mx-auto">
        <div className="section-img-container-lg interview-bg"></div>
          <div className="flex-1 flex flex-col justify-center">
            <span className="text-4xl font-bold">INTERVIEW WITH US</span>
            <p>
              It's simple! You provide the resume, we provide the interview questions.
            </p>
          </div>
        </div>
      </div>
      <div className="section-container text-left">
        <div className="flex w-2/3 mx-auto">
          <div className="section-img-container-lg question-bg"></div>
          <div className="flex-1 flex flex-col justify-center">
            <span className="text-4xl font-bold">500+ QUESTIONS AND COUNTING</span>
            <p>
              We've got the most relevant questions and new ones are being added frequently.
            </p>
          </div>
        </div>
      </div>
      <div className="section-container text-center mt-20 mb-20">
        <span className="text-4xl font-bold">4 EASY STEPS</span>
        <p>With these simple steps, you can start practicing in no time!</p>
        <div className="steps-container flex flex-wrap w-9/12 mx-auto mt-8">
          <div className="flex-1 text-center">
            <div className="section-img-container upload-resume"></div>
            <div className="text-lg font-bold mt-2">Upload Your Resume</div>
            <span className="mt-2 inline-block">
              Upload your resume in PDF or DOC format. Otherwise, enter your skills separately.
            </span>
          </div>
          <div className="flex-1 text-center">
            <div className="section-img-container select-company"></div>
            <div className="text-lg font-bold mt-2">Select A Company</div>
            <span className="mt-2 inline-block">
              Select from a list of mock companies that requires your particular skillset.
            </span>
          </div>
          <div className="flex-1 text-center">
            <div className="section-img-container questions"></div>
            <div className="text-lg font-bold mt-2">Interview With Us</div>
            <span className="mt-2 inline-block">
              We will provide a list of questions based on your skill set and simulate an interview.
            </span>
          </div>
          <div className="flex-1 text-center">
            <div className="section-img-container review-result"></div>
            <div className="text-lg font-bold mt-2">Review Your Results</div>
            <span className="mt-2 inline-block">
              Review the results of your mock interview and improve your performance.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
