// ----------------------------------------------------------
// Browser's speechSynthesis support minimum browser version
// Using data from https://caniuse.com/speech-synthesis
// ----------------------------------------------------------
enum SpeechMinBrowserVersion {
  Edge = 85,
  Firefox = 81,
  Chrome = 85,
  Safari = 13,
  Opera = 71,
  iOSSafari = 13, // We do not support this at this moment
  AndroidChrome = 86, // We do not support this at this moment
  AndroidFirefox = 82, // We do not support this at this moment
  SamsungInternet = 12, // We do not support this at this moment
  QQ = 10.4, // We do not support this at this moment
  KaiOS = 2.5, // We do not support at this this moment
}

function getBrowser() {
  let ua = navigator.userAgent,
    tem,
    M =
      ua.match(
        /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
      ) || [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return { name: "IE", version: tem[1] || "" };
  }
  if (M[1] === "Chrome") {
    tem = ua.match(/\bOPR\/(\d+)/);
    if (tem != null) {
      return { name: "Opera", version: tem[1] };
    }
  }
  if (window.navigator.userAgent.includes("Edge")) {
    tem = ua.match(/\Edge\/(\d+)/);
    if (tem != null) {
      return { name: "Edge", version: tem[1] };
    }
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];
  if ((tem = ua.match(/version\/(\d+)/i)) != null) {
    M.splice(1, 1, tem[1]);
  }

  // Device type
  let device = "computer";
  if (ua.includes("CPU iPad")) {
    device = "tablet";
  } else if (ua.includes("CPU iPhone") || ua.includes("Mobile")) {
    device = "mobile";
  }

  return {
    name: M[0],
    version: +M[1],
    device,
  };
}

function isSpeechSupported() {
  const browser = getBrowser();
  let supported = false;
  if (browser.name === "Chrome" && browser.version >= SpeechMinBrowserVersion.Chrome) {
    supported = true;
  } else if (browser.name === "Edge" && browser.version >= SpeechMinBrowserVersion.Edge) {
    supported = true;
  } else if (browser.name === "Firefox" && browser.version >= SpeechMinBrowserVersion.Firefox) {
    supported = true;
  } else if (browser.name === "Safari" && browser.version >= SpeechMinBrowserVersion.Safari && browser.device !== "mobile") {
    supported = true;
  } else if (browser.name === "Opera" && browser.version >= SpeechMinBrowserVersion.Opera) {
    supported = true;
  }
  return supported;
}

export { isSpeechSupported };
