import React, { ReactNode } from "react";

import {
  Switch,
  Route,
  RouteProps,
  Redirect,
  useLocation,
  Link,
} from "react-router-dom";
import { Button } from "antd";

import { useAuth } from "context/authContext";
import { PublicLayout, PrivateLayout } from "components/Layout";
import FullPageSpinner from "components/FullPageSpinner";
import Home from "screens/Home";
import Registration from "screens/Registration";
import Login from "screens/Login";
import Pricing from "screens/Pricing";
import About from "screens/About";
import ContactUs from "screens/ContactUs";
import ResetPassword from "screens/ResetPassword";
import ForgotPassword from "screens/ForgotPassword";
// import Recorder from "screens/Recorder";
import PrivacyPolicy from "screens/PrivacyPolicy";
import TermsOfService from "screens/TermsOfService";
import Interview from "screens/Interview/Interview";
import WaitingRoom from "screens/WaitingRoom/WaitingRoom";
import Jobs from "screens/Jobs/Jobs";
import Skills from "screens/Skills";
import ResumeUpload from "screens/ResumeUpload";
import InterviewResult from "screens/InterviewResults/InterviewResult";
import InterviewResultList from "screens/InterviewResults/interviewResultList";
import VerifyEmail from "screens/VerifyEmail";

type PublicRouteProps = {
  children: ReactNode;
  spinner?: boolean;
} & RouteProps;

function PublicRoute({ children, spinner, ...props }: PublicRouteProps) {
  const { isLoading } = useAuth();
  if (spinner && isLoading) {
    return <FullPageSpinner />;
  }

  return (
    <Route {...props}>
      <PublicLayout>{children}</PublicLayout>
    </Route>
  );
}

function PrivateRoute({ children, ...props }: PublicRouteProps) {
  const location = useLocation();
  const { user, isLoading } = useAuth();

  if (isLoading) {
    return <FullPageSpinner />;
  } else if (!user) {
    const redirectUrl = `${location.pathname}${location.search}`;

    return (
      <Redirect
        to={{
          pathname: "/login",
          search: `?redirect=${encodeURIComponent(redirectUrl)}`,
        }}
      />
    );
  }

  return (
    <Route {...props}>
      <PrivateLayout>{children}</PrivateLayout>
    </Route>
  );
}

function App() {
  return (
    <Switch>
      <PrivateRoute path="/app">
        <div>Home</div>
      </PrivateRoute>
      <PrivateRoute path="/jobs">
        <Jobs />
      </PrivateRoute>
      <PrivateRoute path="/resume-upload">
        <ResumeUpload />
      </PrivateRoute>
      <PrivateRoute path="/waitingroom/:jobId">
        <WaitingRoom />
      </PrivateRoute>
      <PrivateRoute path="/interview/:interviewId/result">
        <InterviewResult />
      </PrivateRoute>
      <PrivateRoute path="/interview/:interviewId">
        <Interview />
      </PrivateRoute>
      <PrivateRoute path="/review-skills">
        <Skills />
      </PrivateRoute>
      <PrivateRoute path="/results">
        <InterviewResultList />
      </PrivateRoute>

      <PublicRoute path="/login" spinner>
        <Login />
      </PublicRoute>
      <PublicRoute path="/register" spinner>
        <Registration />
      </PublicRoute>
      <PublicRoute path="/verify-email/:id?/:token?">
        <VerifyEmail />
      </PublicRoute>
      <PublicRoute path="/about">
        <About />
      </PublicRoute>
      <PublicRoute path="/pricing">
        <Pricing />
      </PublicRoute>
      <PublicRoute path="/reset-password/:x/:token">
        <ResetPassword />
      </PublicRoute>
      <PublicRoute path="/forgot-password">
        <ForgotPassword />
      </PublicRoute>
      <PublicRoute path="/contact">
        <ContactUs />
      </PublicRoute>
      {/* <PublicRoute path="/record">
        <Recorder />
      </PublicRoute> */}
      <PublicRoute path="/privacy">
        <PrivacyPolicy />
      </PublicRoute>
      <PublicRoute path="/terms">
        <TermsOfService />
      </PublicRoute>
      <PublicRoute exact path="/">
        <Home />
      </PublicRoute>
      <PublicRoute path="*">
        {/* No Match */}
        <div className="mx-auto max-w-md">
          <h1 className="text-xl lg:text-2xl xl:text-3xl">404 Not Found</h1>
          <p>
            This link may be broken, or the page may have been removed. Please
            check to see if the link is correct.
          </p>
          <div>
            <Link to="/jobs">
              <Button type="primary" shape="round">
                Back to Home
              </Button>
            </Link>
          </div>
        </div>
      </PublicRoute>
    </Switch>
  );
}

export default App;
