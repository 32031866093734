import MicRecorder, { RecorderClass } from "mic-recorder-to-mp3";

class Recorder {
  private recorder: RecorderClass;
  constructor(bitrate: number) {
    this.recorder = new MicRecorder({ bitRate: bitrate });
  }
  public start(): Promise<any> {
    return this.recorder.start();
  }
  public stop(): Recorder {
    this.recorder.stop();
    return this;
    // return this.recorder.stop();
  }
  public getMp3(): Promise<[BlobPart[], Blob]> {
    return this.recorder.getMp3();
  }
  public getMp3File(filename: string): Promise<File> {
    return this.getMp3().then(
      ([buffer, blob]) =>
        new File(buffer, filename, {
          type: blob.type,
          lastModified: Date.now(),
        })
    );
  }
}

export default Recorder;
