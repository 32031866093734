import React, { ReactNode, useState, useLayoutEffect } from "react";

import { AxiosError, AxiosResponse } from "axios";

import * as authClient from "utils/authClient";

type Props = {
  children: ReactNode;
};

type User = {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  has_skills: boolean;
  notify_new_question: boolean;
};

type ContextProps =
  | {
      user: User | undefined;
      login: any;
      logout: any;
      register: any;
      setUser: React.Dispatch<React.SetStateAction<User | undefined>>;
      isLoading: boolean;
    }
  | undefined;

// const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const AuthContext = React.createContext<ContextProps>(undefined);

function AuthProvider(props: Props) {
  // const [firstAttemptFinished, setFirstAttemptFinished] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState<User | undefined>(undefined);

  useLayoutEffect(() => {
    // TODO: Check if user is logged in e.g. auth.isLoggedIn()
    // If is logged in, then call authClient.getUser()
    // authClient.isLoggedIn();
    authClient
      .getUser()
      .then((response: AxiosResponse) => {
        const userObject = response.data;
        setUser(userObject.data);
      })
      .catch((error: AxiosError) => {
        console.log(error);
      })
      .finally(() => {
        // setFirstAttemptFinished(true);
        setIsLoading(false);
      });
  }, []);

  function login(data: Record<string, string>) {
    // TODO: call authClient.login() then setUser()
    return authClient.login(data);
  }

  function register(form: FormData) {
    return authClient.register(form);
  }

  function logout() {
    // TODO: Platform API needs to have a way to blacklist current token.
    return authClient.logout();
  }

  // if (!firstAttemptFinished) {
  //   if (isLoading) {
  //     return (
  //       <div className="w-screen h-screen flex justify-center items-center">
  //         <Spin indicator={antIcon} />
  //       </div>
  //     );
  //   }
  // }

  return (
    <AuthContext.Provider
      value={{ user, login, logout, register, setUser, isLoading }}
      {...props}
    />
  );
}

function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within a AuthProvider");
  }
  return context;
}

export { AuthProvider, useAuth };
