import React, { useEffect, useState } from "react";
import { Button, Skeleton } from "antd";
import useIsMounted from "hooks/useIsMounted";
import { Link } from "react-router-dom";
import * as interviewService from "services/interviewService";
import Moment from "react-moment";

type MockResults = {
  id: number;
  title: string;
  company_name: string;
  description: string;
  location: string;
  updated_at: Date;
};

function InterviewResultList() {
  const isMounted = useIsMounted();
  const [loading, setLoading] = useState(true);
  const [results, setResults] = useState<MockResults[]>([]);

  const loadingCards = [1, 2, 3].map((item, index) => (
    <div
      key={index}
      className={`job-item-container max-w-xs rounded overflow-hidden shadow-lg inline-block mr-4 mb-4 bg-white w-1/5`}
    >
      <div className="h-48 bg-gray-300"></div>
      <div className="px-6 py-4">
        <Skeleton active />
      </div>
    </div>
  ));

  useEffect(() => {
    interviewService
      .getMockInterviewResultList()
      .then((response) => {
        if (isMounted.current) {
          setResults(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
        if (isMounted.current) {
          setLoading(false);
        }
      })
      .finally(() => {
        if (isMounted.current) {
          setLoading(false);
        }
      }); 
  }, [isMounted]);

  function randomImage() {
    return "/office-" + (Math.floor(Math.random() * 3) + 1) + ".jpg";
  }

  const resultList = results.map((job: any, index: number) => {
    return (
      <div
        key={index}
        className={`job-item-container max-w-xs rounded overflow-hidden shadow-lg inline-block mr-4 mb-4 bg-white `}
      >
        <img className="w-full" src={randomImage()} alt={job.company_name} />
        <div className="px-6 py-4">
          <div className="font-bold text-lg truncate" title={job.title}>
            {job.title}
          </div>
          <p className="text-gray-500 text-basemt-2 mb-2">{job.company_name}</p>
          <p className="text-gray-700 text-basemt-2 h-20 overflow-auto">{job.description}</p>
        </div>
        <div className="px-4 py-2 flex justify-between border-t">
          <span className="py-1">
            <Moment fromNow date={job.updated_at}></Moment>
          </span>
          <Button type="primary" shape="round" className="">
            <Link to={"/interview/" + job.interview_id + "/result"}>
              View Result
            </Link>
          </Button>
        </div>
      </div>
    );
  });

  return (
    <div className="interview-results-list-container">
      <div className="header-container mb-6">
        <h1 className="text-2xl font-semibold mb-0">Interview Results</h1>
        <p>These are the result of all your interviews.</p>
      </div>
      {loading ? (
        <div>{loadingCards}</div>
      ) : (
        <>
          <div className="job-container">
            {results.length === 0 ? <p>No jobs. :(</p> : resultList}
          </div>
        </>
      )}
    </div>
  );
}

export default InterviewResultList;
