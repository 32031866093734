import React, { useEffect, useState, useRef } from "react";
import { Button, notification } from "antd";
import { Link, useParams } from "react-router-dom";
import useIsMounted from "hooks/useIsMounted";
import * as interviewService from "services/interviewService";
import { getMockJob } from "services/jobService";
import { AxiosResponse } from "axios";
import * as SpeechService from "services/speechService";
import { CloseCircleOutlined } from "@ant-design/icons";

function WaitingRoom() {
  const isMounted = useIsMounted();
  const [loading, setLoading] = useState(true);
  const [speaking, setSpeaking] = useState(false);
  const [listening, setListening] = useState(false);
  const [interview, setInterview] = useState({
    id: undefined,
  });

  const params: any = useParams();
  const jobId: number = params.jobId;
  // const skillIds: number = params.jobskillIdsId;

  const audioContext = React.useRef<AudioContext | undefined>();

  // web audio
  // const audioContext = window.AudioContext;
  // const stream = window.navigator.getUserMedia({ audio: true}, (stream) => {}, (err) => {});

  const speakerTestSpeech =
    "Good Morning! You must be Bill. Please wait here and I will let Steve know that you are here.";

  const speech = useRef<SpeechSynthesisUtterance | false>(false);

  // TODO: Skill Id is hard-coded for now
  useEffect(() => {
    getMockJob(jobId)
      .then((jobResponse: AxiosResponse) => {
        const skillIds = jobResponse.data.data.skills.map(
          (skill: any) => skill.id
        );
        return interviewService.initMockInterview(jobId, skillIds.join());
      })
      .then((response: AxiosResponse) => {
        if (isMounted.current) {
          setInterview(response.data.data);
        }
      })
      .catch((error) => {
        if (isMounted.current) {
          setLoading(false);
        }
      })
      .finally(() => {
        if (isMounted.current) {
          setLoading(false);
        }
      });

    // startListening();

    return () => {
      if (speech.current) {
        SpeechService.stop();
      }
    };
  }, [isMounted, jobId]);

  function playTestMessage() {
    setSpeaking(true);

    if (speech.current) {
      SpeechService.stop();
    }
    speech.current = SpeechService.speak({ text: speakerTestSpeech });

    if (speech.current) {
      speech.current.onend = () => {
        if (isMounted.current) {
          setSpeaking(false);
        }
      };
    }
  }

  function stopListening() {
    if (audioContext.current) {
      audioContext.current.close();
      const canvas = document.getElementById(
        "volume-meter"
      ) as HTMLCanvasElement;
      const canvasContext = canvas.getContext("2d");
      if (canvasContext) {
        canvasContext.clearRect(0, 0, 200, 25);
        canvasContext.fillRect(0, 0, 0, 25);
      }
      setListening(false);
    }
  }

  function startListening() {
    // window.navigator.getUserMedia = window.navigator.getUserMedia ||
    //  window.navigator.webkitGetUserMedia ||
    //   window.navigator.mozGetUserMedia;

    // navigator.getUserMedia = window.navigator.getUserMedia;
    if (navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices.getUserMedia({ audio: true })
        .then((stream) => {
          setListening(true);
          audioContext.current = new AudioContext();
          const analyser = audioContext.current.createAnalyser();
          const microphone = audioContext.current.createMediaStreamSource(
            stream
          );
          const javascriptNode = audioContext.current.createScriptProcessor(
            2048,
            1,
            1
          );

          analyser.smoothingTimeConstant = 0.8;
          analyser.fftSize = 1024;

          microphone.connect(analyser);
          analyser.connect(javascriptNode);
          javascriptNode.connect(audioContext.current.destination);

          const canvas = document.getElementById(
            "volume-meter"
          ) as HTMLCanvasElement;
          const canvasContext = canvas.getContext("2d");

          javascriptNode.onaudioprocess = function () {
            var array = new Uint8Array(analyser.frequencyBinCount);
            analyser.getByteFrequencyData(array);
            var values = 0;

            var length = array.length;
            for (var i = 0; i < length; i++) {
              values += array[i];
            }

            const average = values / length;

            if (canvasContext) {
              canvasContext.clearRect(0, 0, 200, 20);
              canvasContext.fillStyle = "#66c378";
              canvasContext.fillRect(0, 0, average * 4, 20);
              canvasContext.fillStyle = "#262626";
              canvasContext.font = "48px impact";
              // canvasContext.fillText("" + Math.round(average - 40), -2, 200);
            }
          }; // end fn stream
        })
        .catch((err) => {
          let message = err.message;          
          if (err.code === 8) {
            message = "Please connect a microphone to your computer.";
          }
          notification.open({
            message: "An Error Occured",
            description: message,
            icon: <CloseCircleOutlined style={{ color: "red" }} />,
          });
        });    
    } else {
      notification.open({
        message: "An Error Occured",
        description: "Your browser does not support getUserMedia",
        icon: <CloseCircleOutlined style={{ color: "red" }} />,
      });
    }
  }

  return (
    <div className="waiting-room-container">
      <div className="header-container">
        <h1 className="text-2xl font-semibold mb-0">Waiting Room</h1>
        <p>Before we begin, please check your microphone and speaker.</p>
      </div>
      <div className="testing-container mb-8">
        <div className="flex">
          <div className="flex-1 border p-4 rounded shadow-lg bg-white p-5 mr-8">
            <h3 className="text-lg font-semibold mb-0">Step 1</h3>
            <p className="">Check Your Speaker</p>
            <Button
              type="primary"
              shape="round"
              onClick={() => playTestMessage()}
              disabled={speaking}
            >
              {speaking ? "Playing Test Message" : "Test"}
            </Button>
          </div>
          <div className="flex-1 border p-4 rounded shadow-lg bg-white p-5 mr-8">
            <h3 className="text-lg font-semibold mb-0">Step 2</h3>
            <p className="">Check your microphone</p>
            <div className="test-container flex">
              <div className="flex-1">
                {listening ? (
                  <Button
                    type="primary"
                    shape="round"
                    onClick={() => stopListening()}
                  >
                    Stop
                  </Button>
                ) : (
                  <Button
                    type="primary"
                    shape="round"
                    onClick={() => startListening()}
                  >
                    Test
                  </Button>
                )}
              </div>
              <div className="volume-meter-container flex">
                <canvas
                  id="volume-meter"
                  className="volume-meter"
                  width="200"
                  height="20"
                ></canvas>
              </div>
            </div>
          </div>
          <div className="flex-1 border p-4 rounded shadow-lg bg-white p-5">
            <h3 className="text-lg font-semibold mb-0">Step 3</h3>
            <p className="">
              My speaker and microphone are working. Let's get started!
            </p>
            <Button
              type="primary"
              shape="round"
              disabled={loading || !interview.id}
            >
              <Link to={"/interview/" + interview.id}>Start Interview</Link>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WaitingRoom;
