import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { Result } from "antd";
import Form from "./Form";
import WaveBg from "../../resources/images/wave-bg.svg";
import { useAuth } from "context/authContext";

type Mode = "form" | "success";

function Registration() {
  const [mode, setMode] = useState<Mode>("form");
  const { user } = useAuth();

  if (user) {
    return <Redirect to="/jobs" />;
  }

  return (
    <>
      {mode === "success" ? (
        /* TODO: Should we add a resend email button here? */
        <Result
          status="success"
          title="Thank you!"
          subTitle="Please check your inbox for a confirmation link."
        />
      ) : (
        <div className="">
          <div className="billboard">
            <div className="relative">
              <img src={WaveBg} alt="" />
            </div>
          </div>
          <div className="mx-auto max-w-md mt-8 px-4">
            <h1 className="text-3xl font-bold">Acount Sign Up</h1>
            <p>
              Start practicing interview for <strong>FREE!</strong>
            </p>
            <Form onSuccess={() => setMode("success")} />
          </div>
        </div>
      )}
    </>
  );
}

export default Registration;
