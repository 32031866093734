import { API } from "utils/axiosInstance";

type Skill = {
  id: string;
};

function getUserSkills() {
  return API.get("/users/skills");
}

function updateUserSkills(newSkills: Skill[]) {
  return API.put("/users/skills", {
    skillId: newSkills,
  });
}

function uploadResume(formData: any) {
  return API.post("/users/upload", formData);
}

function updateUserPreference(preference: any) {
  return API.put("/users/preference", {
    preference,
  });
}

export { getUserSkills, updateUserSkills, uploadResume, updateUserPreference };
