import React, { ReactNode, useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import { Button, Divider } from "antd";

import FullPageSpinner from "components/FullPageSpinner";
import { verifyEmail } from "services/authService";
import useIsMounted from "hooks/useIsMounted";

type MatchParams = {
  id: string;
  token: string;
};

type BodyProps = {
  title?: string;
  children: ReactNode;
};

function isIdAndTokenValid(id: string | undefined, token: string | undefined) {
  return (
    id !== undefined &&
    id !== "undefined" &&
    token !== undefined &&
    token !== "undefined"
  );
}

function Body({ title, children }: BodyProps) {
  return (
    <section>
      <div className="mx-auto max-w-md mt-8 px-4">
        {title && <h1 className="text-3xl font-bold">{title}</h1>}
        {children}
      </div>
    </section>
  );
}

function VerifyEmail() {
  const isMounted = useIsMounted();
  const { id, token } = useParams<MatchParams>();
  const [isLoading, setIsLoading] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [message, setMessage] = useState("");
  const verificationFailed = (
    <Body title="Unable to verify email">
      <div>
        {message}
        <Divider>OR</Divider>
        <div className="text-center">
          <Button type="primary" shape="round" href="/login">
            Resend Verification Email
          </Button>
        </div>
      </div>
    </Body>
  );

  useEffect(() => {
    if (isIdAndTokenValid(id, token)) {
      setIsLoading(true);
      verifyEmail(id, token)
        .then((response) => {
          if (isMounted.current) {
            setIsVerified(response.data.data.email_verified);
            setMessage(response.data.message);
          }
        })
        .catch((error) => {
          if (isMounted.current) {
            setIsVerified(false);
            setMessage("Please click the link in the verification email.");
          }
        })
        .finally(() => {
          if (isMounted.current) {
            setIsLoading(false);
          }
        });
    }
  }, [id, token, isMounted]);

  if (!isIdAndTokenValid(id, token)) {
    return verificationFailed;
  }

  return (
    <>
      {isLoading ? (
        <FullPageSpinner />
      ) : isVerified ? (
        <Body title="Email Verified!">
          <p>{message}</p>
          <Button type="primary" shape="round" href="/login">
            Login
          </Button>
        </Body>
      ) : (
        verificationFailed
      )}
    </>
  );
}

export default VerifyEmail;
