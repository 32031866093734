import { API } from "utils/axiosInstance";

const path = "/interview";

function getQuestions(interviewId: number) {
  return API.get(path + "/" + interviewId);
}

function initMockInterview(jobId: number, skillIds: string) {
  return API.post(path + "/init", {
    jobId: jobId,
    skillIds: skillIds,
  });
}

function getMockInterviewResult(interviewId: number) {
  return API.get(`${path}/${interviewId}/result`);
}

function getMockInterviewResultList() {
  return API.get(`${path}/result`);
}

function getMockInterviewJob(interviewId: number) {
  return API.get(`${path}/${interviewId}/job`);
}

function updateMockAnswer(
  interviewId: number,
  questionId: number,
  formData: FormData
) {
  return API.put(`${path}/${interviewId}/questions/${questionId}`, formData);
}

export {
  getQuestions,
  initMockInterview,
  getMockInterviewResult,
  getMockInterviewJob,
  updateMockAnswer,
  getMockInterviewResultList,
};
