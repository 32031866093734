import { API } from "utils/axiosInstance";

export type ContactMessage = {
  name: string;
  email: string;
  message: string;
  reason: string;
};

function sendMessage(message: ContactMessage) {
  return API.post("/contact", message);
}

const supportService = { sendMessage };
export default supportService;
