import React, { useState } from "react";

import { Select, Spin } from "antd";
import { SelectProps } from "antd/lib/select";
import { PlusCircleOutlined } from "@ant-design/icons";
import { AxiosError, AxiosResponse } from "axios";
import { debounce } from "lodash";
import { LoadingOutlined } from "@ant-design/icons";

import * as SkillService from "services/skillService";

export type Skill = {
  id: string;
  category_id: null;
  name: string;
  lower_name: string;
};
type InjectedProps = {
  onChange: (value: Skill | undefined) => void;
  clearOnSelect?: boolean;
  formatOptionLabel: (value: Skill | undefined | []) => any;
  formatOptionValue: (value: Skill | undefined | []) => any;
};
type SkillsSelectorProps = InjectedProps &
  Omit<SelectProps<any>, keyof InjectedProps>;

function SkillsSelector(props: SkillsSelectorProps) {
  const {
    onChange,
    clearOnSelect = true,
    formatOptionLabel,
    formatOptionValue,
    ...restProps
  } = props;
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState<Skill[]>([]);
  const [valueObject, setValueObject] = useState<Skill | undefined | []>();

  function handleSearch(value: string) {
    setLoading(true);
    SkillService.search(value)
      .then((response: AxiosResponse) => {
        setOptions(response.data.data);
      })
      .catch((error: AxiosError) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const debouncedSearch = debounce(handleSearch, 300);

  function handleChange(value: any) {
    if (value) {
      const selectedObject = options.find((opt) => opt.id === value);
      onChange && onChange(selectedObject);
      if (clearOnSelect) {
        // https://github.com/ant-design/ant-design/issues/24310
        // To deselect an item in Ant Design 4 Select, use []
        setValueObject([]);
      } else {
        setValueObject(selectedObject);
      }
    } else {
      setValueObject(value);
      onChange && onChange(value);
    }
  }

  return (
    <Select
      showAction={["focus", "click"]}
      className="bg-white rounded-lg shadow-md flex-grow"
      style={{
        padding: "3px 0",
      }}
      value={valueObject && formatOptionValue(valueObject)}
      showSearch
      showArrow={false}
      placeholder="Add New Skill"
      size="middle"
      bordered={false}
      suffixIcon={<PlusCircleOutlined />}
      notFoundContent={
        loading ? <Spin spinning indicator={<LoadingOutlined />} /> : null
      }
      onSearch={debouncedSearch}
      onChange={handleChange}
      filterOption={false}
      allowClear={true}
      {...restProps}
    >
      {options.map((option) => (
        <Select.Option
          key={option.id}
          value={formatOptionValue(option)}
          title={formatOptionLabel(option)}
        >
          {formatOptionLabel(option)}
        </Select.Option>
      ))}
    </Select>
  );
}

export default SkillsSelector;
