import React, { useState, useEffect, useCallback } from "react";
import recordService from "services/recordService";

function useRecorder(bitRate: number = 128) {
  const [isRecording, setIsRecording] = useState(false);
  const [isBlocked, setIsBlocked] = useState(true);
  const [hasStopped, setHasStopped] = useState(false);
  const recorderRef = React.useRef<recordService>(new recordService(bitRate));
  // const { start, stop, getMp3, getMp3File } = recorderRef.current;

  function startRecording() {
    if (isBlocked) {
      console.log("Permission Denied.");
    } else {
      setIsRecording(true);
      setHasStopped(false);
      return recorderRef.current
        .start()
        .then(() => console.log("Recording started"));
    }
  }

  const memoizedStartRecording = useCallback(startRecording, [
    isBlocked,
    recorderRef,
  ]);

  function stopRecording() {
    if (isRecording) {
      setIsRecording(false);
      console.log("Recording stopped");
      setHasStopped(true);
      return recorderRef.current.stop();
    } else {
      console.log("Recording was never started.");
    }
  }

  const memoizedStopRecording = useCallback(stopRecording, [
    isRecording,
    recorderRef,
  ]);

  function getMp3() {
    // if (!isRecording && hasStopped) {
    //   return recorderRef.current.getMp3();
    // } else if (!hasStopped) {
    //   console.log("Nothing to download. Please record first.");
    // } else {
    //   console.log("Still Recording. Please stop recorder before getting Mp3.");
    // }
    if (isRecording) {
      stopRecording();
    }
    return recorderRef.current.getMp3();
  }

  useEffect(() => {
    navigator.mediaDevices.getUserMedia({ audio: true })
    .then(() => {
      // Success callback
      console.log("Permission Granted");
      setIsBlocked(false);
    })
    .catch(() => {
      // Error callback
      console.log("Permission Denied");
      setIsBlocked(true);
    });

    // let recorder = recorderRef.current;

    return () => {
      if (isRecording) {
        // recorder.stop();
        memoizedStopRecording();
      }
    };
  }, [isRecording, memoizedStopRecording]);

  return {
    isRecording,
    isBlocked,
    hasStopped,
    startRecording,
    memoizedStartRecording,
    stopRecording,
    getMp3,
  };
}

export { useRecorder };
