import { API } from "utils/axiosInstance";

// Mock request
// const sleep = (time: number) =>
//   new Promise((resolve) => setTimeout(resolve, time));

function login(data: Record<"email" | "password", string>) {
  return API.post("auth/login", data);
}
function logout() {
  // TODO: Platform API needs to blacklist token
  return API.post("auth/logout");
}
function register(data: Record<string, any>) {
  // TODO: Decide whether to sign up with occupationId or not.
  return API.post("auth/signup", { ...data, occupationId: "1" });
}
function getUser() {
  return API.get("me");
}
function forgotPassword(data: Record<"email", string>) {
  return API.post("users/resetpassword", data);
}
function resetPassword(
  id: string,
  token: string,
  data: Record<"newPassword", string>
) {
  return API.post(`users/resetpassword/${id}/${token}`, data);
}
// function changePassword() {} // TODO: For logged in users, requires current password

function verifyEmail(id: string, token: string) {
  return API.post(`users/verify-email/${id}/${token}`);
}

function resendVerificationEmail(token: string) {
  return API.post(`auth/resend-verification`, { emailToken: token });
}

export {
  login,
  logout,
  register,
  getUser,
  forgotPassword,
  resetPassword,
  verifyEmail,
  resendVerificationEmail,
};
