import React, { useState } from "react";

import { useForm, Controller } from "react-hook-form";
import { Alert, Button, Input } from "antd";
import { Redirect } from "react-router-dom";

import useIsMounted from "hooks/useIsMounted";
import { useAuth } from "context/authContext";
import { forgotPassword } from "services/authService";

function ForgotPassword() {
  const isMounted = useIsMounted();
  const { user } = useAuth();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState<string>("");
  const { control, handleSubmit, reset } = useForm();

  function onSubmit(data: Record<string, string>) {
    setMessage("");
    setIsSubmitting(true);
    forgotPassword(data)
      .then((response) => {})
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        if (isMounted.current) {
          // Display
          setMessage(
            "If account exists, you will receive an email for further instructions."
          );
          reset();
          setIsSubmitting(false);
        }
      });
  }

  // Redirect to /settings if user is logged in
  if (user) {
    return <Redirect to="/settings" />;
  }

  return (
    <section>
      <div className="mx-auto max-w-md mt-8 px-4">
        <h1 className="text-3xl font-bold">Forgot your password?</h1>
        {message && (
          <div className="mb-6">
            <Alert type="info" message={message} />
          </div>
        )}
        <p>
          Enter the email address you used when you signed up and we'll send you
          instructions to reset your password.
        </p>
        <form className="mb-4" onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-6">
            <Controller
              as={Input}
              name="email"
              type="email"
              control={control}
              placeholder="Enter your email address"
              required
              disabled={isSubmitting}
            />
          </div>
          <Button
            shape="round"
            type="primary"
            className="flex"
            htmlType="submit"
            disabled={isSubmitting}
          >
            {isSubmitting ? "Loading ..." : "Send Instructions"}
          </Button>
        </form>
      </div>
    </section>
  );
}

export default ForgotPassword;
