import React, { useState, useEffect, useRef } from "react";

import { useParams, useHistory } from "react-router-dom";
import { Avatar, Statistic, Button, message } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { AxiosResponse } from "axios";
import ReactMarkdown from "react-markdown";

import * as SpeechService from "services/speechService";
import * as interviewService from "services/interviewService";
import useIsMounted from "hooks/useIsMounted";
import { useRecorder } from "hooks/recorderHooks";
import { useAuth } from "context/authContext";

type MockInterview = {
  id: number;
  question: string;
  topic: string;
  category: string;
  difficulty: number | null;
  description: string | null;
  type: string | null;
};

function Interview() {
  const isMounted = useIsMounted();
  const [loading, setLoading] = useState(true);
  const [speaking, setSpeaking] = useState(false);
  const [currentQuestionIndex, setQuestionIndex] = useState(0);
  const { Countdown } = Statistic;
  const [remainingTime, setRemainingTime] = useState(
    Date.now() + 1000 * 60 * 3
  ); // 3 minutes
  const [questions, setQuestions] = useState<MockInterview[]>([]);
  const history = useHistory();

  const params: any = useParams();
  const interviewId: number = params.interviewId;

  const { user } = useAuth();
  const {
    isRecording,
    // isBlocked, // TODO: Do we need to show a view when microphone is blocked?
    // hasStopped,
    // startRecording,
    memoizedStartRecording,
    stopRecording,
    getMp3,
  } = useRecorder();

  const speech = useRef<SpeechSynthesisUtterance | false>(false);

  // const speak = (text: string): void => {
  //   setSpeaking(true);
  //   const speech: any = SpeechService.speak({ text });

  //   // Callback for the speech
  //   speech.onend = () => {
  //     if (isMounted.current) {
  //       setSpeaking(false);
  //       setRemainingTime(Date.now() + 1000 * 60 * 3);

  //       // Start recording
  //       startRecording();
  //     }
  //   };
  // };

  const nextQuestion = (): void => {
    const nextQuestionIndex = currentQuestionIndex + 1;
    setLoading(true);

    // Save answer
    getMp3()
      .then(([buffer, blob]) => {
        return saveAnswer(buffer, blob);
      })
      .then((response: AxiosResponse | undefined) => {
        if (response) {
          if (questions.length >= nextQuestionIndex) {
            if (isMounted.current) {
              setQuestionIndex(nextQuestionIndex);
            }
          }
        } else {
          message.error("Error saving answer");
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        if (isMounted.current) {
          setLoading(false);
        }
      });
  };

  const prevQuestion = (): void => {
    const prevQuestionIndex = currentQuestionIndex - 1;
    setLoading(true);

    // Save answer
    getMp3()
      .then(([buffer, blob]) => {
        return saveAnswer(buffer, blob);
      })
      .then((response: AxiosResponse | undefined) => {
        if (response) {
          if (currentQuestionIndex > 0) {
            if (isMounted.current) {
              setQuestionIndex(prevQuestionIndex);
            }
          }
        } else {
          message.error("Error saving answer");
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        if (isMounted.current) {
          setLoading(false);
        }
      });
  };

  // const completeInterview = (): void => {
  //   // Todo: save the last interview question

  //   // After save is complete
  //   history.push(`/interview/${interviewId}/result`);
  // };

  function saveAnswer(buffer: BlobPart[], blob: Blob) {
    if (blob.size > 3.5 * 1024 * 1024) {
      message.error("Answer exceeded limit");
    } else {
      const currentQuestion = questions[currentQuestionIndex];
      const questionId = currentQuestion.id;
      const file = new File(
        buffer,
        `${user?.id}-${interviewId}-${questionId}.mp3`
      );
      const formData = new FormData();
      formData.append("file", file);

      return interviewService.updateMockAnswer(
        interviewId,
        questionId,
        formData
      );
    }
  }

  function handleFinishedCountdown() {
    if (isRecording) {
      stopRecording();
    }
  }

  useEffect(() => {
    if (questions.length > 0 && currentQuestionIndex === questions.length) {
      history.push(`/interview/${interviewId}/result`);
    } else if (questions.length > 0) {
      // speak(questions[currentQuestionIndex].question);

      if (speech.current) {
        SpeechService.stop();
      }
      speech.current = SpeechService.speak({
        text: questions[currentQuestionIndex].question,
      });
      setSpeaking(true);

      if (speech.current) {
        speech.current.onend = () => {
          if (isMounted.current) {
            setSpeaking(false);
            setRemainingTime(Date.now() + 1000 * 60 * 3);

            // Start recording
            memoizedStartRecording();
          }
        };
      }
    }

    return () => {
      if (speech.current) {
        SpeechService.stop();
        setRemainingTime(Date.now() + 1000 * 60 * 3);
      }
    };
  }, [
    currentQuestionIndex,
    questions,
    isMounted,
    memoizedStartRecording,
    history,
    interviewId,
  ]);

  useEffect(() => {
    interviewService
      .getQuestions(interviewId)
      .then((response: AxiosResponse) => {
        if (isMounted.current) {
          setQuestions(response.data.data);
        }
      })
      .catch((error) => {
        if (isMounted.current) {
          setLoading(false);
        }
      })
      .finally(() => {
        if (isMounted.current) {
          setLoading(false);
        }
      });
  }, [isMounted, interviewId]);

  return (
    <div className="interview-container h-full flex flex-col">
      <div className="header-container">
        <h1 className="text-2xl font-semibold mb-0">Interview</h1>
      </div>
      <div className="toolbar-container h-24 py-4 flex justify-between block">
        <div className="inline-block">
          <span className="block tracking-wide text-gray-700 text-sm mb-2">
            Interviewer
          </span>
          <Avatar icon={<UserOutlined />} />{" "}
          <span className="font-bold text-md">Michael Scott</span>
        </div>
        <div className="inline-block">
          <span className="block tracking-wide text-gray-700 text-sm mb-2">
            Topic
          </span>
          {loading ? (
            <span className="font-bold text-md">Loading ...</span>
          ) : (
            <span className="font-bold text-md">
              {questions[currentQuestionIndex]?.topic}
            </span>
          )}
        </div>
        <div className="inline-block">
          <span className="block tracking-wide text-gray-700 text-sm">
            Time Remaining
          </span>
          {!loading && !speaking ? (
            <Countdown
              value={remainingTime}
              format="mm:ss"
              onFinish={handleFinishedCountdown}
            />
          ) : (
            <span className="text-2xl">--:--</span>
          )}
        </div>
        {/* <div className="flex-1 inline-block">
        <span className="block tracking-wide text-gray-700 text-sm mb-2">Progress</span>
        <div className="w-2/4"><Progress percent={30} size="small" /></div>
      </div> */}
      </div>
      <div className="flex-1 flex flex-col block border rounded shadow-lg bg-white p-4">
        <div className="question-container text-center flex flex-col flex-grow justify-center items-center">
          {loading || !questions || !questions[currentQuestionIndex] ? (
            <p className="text-5xl font-bold w-9/12 mx-auto">Loading ...</p>
          ) : (
            <>
              <p className="text-5xl font-bold w-9/12 mx-auto">
                {questions[currentQuestionIndex]?.question}
              </p>
              {questions[currentQuestionIndex]?.description && (
                <div className="mx-auto my-0 bg-gray-200 p-4 text-left">
                  <ReactMarkdown
                    source={
                      questions[currentQuestionIndex].description || undefined
                    }
                  />
                </div>
              )}
            </>
          )}
        </div>

        {
          <div className="text-center">
            {isRecording ? "Recording ..." : <span>&nbsp;</span>}
          </div>
        }
        <div className="control-container h-18 block">
          <div className="flex justify-between">
            <Button
              shape="round"
              disabled={currentQuestionIndex === 0 || speaking}
              onClick={() => prevQuestion()}
            >
              Previous
            </Button>
            {/* {currentQuestionIndex < questions.length - 1 ? ( */}
            <Button
              type="primary"
              shape="round"
              disabled={loading || speaking}
              onClick={() => nextQuestion()}
            >
              Next
            </Button>
            {/* ) : ( */}
            {/* <Button
                type="primary"
                shape="round"
                disabled={speaking}
                onClick={() => completeInterview()}
              >
                Done
              </Button> */}
            {/* )} */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Interview;
