import React, { useEffect, useState, useRef } from "react";
import { Tag, Skeleton } from "antd";
import useIsMounted from "hooks/useIsMounted";
import { useParams } from "react-router-dom";
import {
  PlayCircleOutlined,
  PauseCircleOutlined,
  EnvironmentFilled,
  DollarCircleFilled,
  ShopFilled,
  ContactsFilled,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import * as interviewService from "services/interviewService";
import Moment from "react-moment";

type MockAnswer = {
  id: number;
  questionId: number;
  question: string;
  topic: string;
  filePath: string;
  updatedAt: number;
};

type MockJob = {
  id: number;
  title: string;
  company_name: string;
  description: string;
  salary_lower: number;
  salary_upper: number;
  location: string;
};

const loadingCards = Array(3)
  .fill(null)
  .map((item, index) => (
    <div
      key={index}
      className="question-container mb-6 bg-white rounded inline-block w-full shadow-md"
    >
      <div className="px-4 py-3 flex justify-between">
        <Skeleton active paragraph={false} />
      </div>
      <div className="border-t border-gray-300 px-4 py-2 flex justify-between">
        <Skeleton
          active
          paragraph={false}
          title={{ width: 60 }}
          className="text-right"
        />
      </div>
    </div>
  ));

function InterviewResult() {
  const isMounted = useIsMounted();
  const params: any = useParams();
  const interviewId: number = params.interviewId;
  const [loading, setLoading] = useState(true);
  const [answers, setAnswers] = useState<MockAnswer[]>([]);
  const [job, setJob] = useState<MockJob[]>([]);
  const [currentAnswerSrc, setCurrentAnswerSrc] = useState("");
  const audioRef = useRef<HTMLAudioElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [invalidAnswers, setInvalidAnswers] = useState<{
    [k: string]: boolean;
  }>({});

  useEffect(() => {
    interviewService
      .getMockInterviewResult(interviewId)
      .then((response) => {
        if (isMounted.current) {
          setAnswers(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
        if (isMounted.current) {
          setLoading(false);
        }
      })
      .finally(() => {
        if (isMounted.current) {
          setLoading(false);
        }
      });

    interviewService
      .getMockInterviewJob(interviewId)
      .then((response) => {
        if (isMounted.current) {
          console.log(response.data.data);
          setJob(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
        if (isMounted.current) {
          setLoading(false);
        }
      })
      .finally(() => {
        if (isMounted.current) {
          setLoading(false);
        }
      });
  }, [isMounted, interviewId]);

  useEffect(() => {
    let ref = audioRef.current;
    if (ref) {
      if (isPlaying) {
        ref.play();
      }
    }

    return () => {
      if (ref) {
        ref.pause();
      }
    };
  }, [isPlaying, currentAnswerSrc]);

  function playAnswer(filePath: string): void {
    // setCurrentAnswerSrc(`${process.env.REACT_APP_RECORDING_BASE}/${filePath}`);
    setCurrentAnswerSrc(`${filePath}`);
    setIsPlaying(true);
  }

  function pauseAnswer(): void {
    if (audioRef.current) {
      audioRef.current.pause();
      setIsPlaying(false);
    }
  }

  const answerList = answers.map((answer: any, index: number) => {
    return (
      <div
        key={index}
        className={`question-container mb-6 bg-white rounded inline-block w-full shadow-md ${
          currentAnswerSrc === answer.file_path && isPlaying
            ? "border-4 border-blue-300"
            : ""
        }`}
      >
        <div className="px-4 py-3 flex justify-between">
          <div className="flex-1 py-1 pr-12">
            <span className="text-lg font-semibold mr-3 text-gray-800">
              {index + 1}.
            </span>
            <span className="text-lg font-semibold text-gray-700 inline-block">
              {answer.question}
            </span>
          </div>
          {invalidAnswers[answer.file_path] ? (
            <div className="flex items-center text-red-600">
              <ExclamationCircleOutlined disabled />
              <span className="ml-2">Not Found</span>
            </div>
          ) : currentAnswerSrc === answer.file_path && isPlaying ? (
            <div
              className="flex items-center text-blue-600 hover:text-blue-400 cursor-pointer"
              onClick={() => pauseAnswer()}
            >
              <PauseCircleOutlined />
              <span className="ml-2">Pause Recording</span>
            </div>
          ) : (
            <div
              className="flex items-center text-blue-600 hover:text-blue-400 cursor-pointer"
              onClick={() => playAnswer(answer.file_path)}
            >
              <PlayCircleOutlined />
              <span className="ml-2">Play Recording</span>
            </div>
          )}
        </div>
        <div className="border-t border-gray-300 px-4 py-2 flex justify-between">
          <div className="text-gray-600 text-sm">
            <Tag color="blue">{answer.topic}</Tag>
          </div>
          <div className="text-gray-600 text-sm">
            <Moment fromNow date={answer.updated_at}></Moment>
          </div>
        </div>
      </div>
    );
  });

  return (
    <div className="interview-results-container">
      <div className="header-container">
        <h1 className="text-2xl font-semibold mb-0">Interview Result</h1>
      </div>
      {job && job.length > 0 ? (
        <div className="job-description flex justify-start py-3 mb-4">
          <div className="job-info-item-container mr-10 flex">
            <div className="w-8 flex justify-center items-center">
              <ShopFilled style={{ fontSize: "28px", color: "#38a169" }} />
            </div>
            <div className="flex-1 ml-2">
              <span className="block">Company Name</span>
              <span className="font-bold text-md text-gray-700">
                {job[0].company_name}
              </span>
            </div>
          </div>
          <div className="job-info-item-container mr-10 flex">
            <div className="w-8 flex justify-center items-center">
              <ContactsFilled style={{ fontSize: "28px", color: "#3182ce" }} />
            </div>
            <div className="flex-1 ml-2">
              <span className="block">Job Title</span>
              <span className="font-bold text-md text-gray-700">
                {job[0].title}
              </span>
            </div>
          </div>
          <div className="job-info-item-container mr-10 flex">
            <div className="w-8 flex justify-center items-center">
              <DollarCircleFilled
                style={{ fontSize: "28px", color: "#ecc94b" }}
              />
            </div>
            <div className="flex-1 ml-2">
              <span className="block">Job Salary</span>
              <span className="font-bold text-md text-gray-700">
                {job[0].salary_lower}k - {job[0].salary_upper}k
              </span>
            </div>
          </div>
          <div className="job-info-item-container mr-10 flex">
            <div className="w-8 flex justify-center items-center">
              <EnvironmentFilled
                style={{ fontSize: "28px", color: "#dd6b20" }}
              />
            </div>
            <div className="flex-1 ml-2">
              <span className="block">Job Location</span>
              <span className="font-bold text-md text-gray-700">
                {job[0].location}
              </span>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      <div className="questions-container">
        {loading ? (
          <>{loadingCards}</>
        ) : answers.length === 0 ? (
          <p>
            You have not answered your interview questions yet. Please try
            again. :(
          </p>
        ) : (
          answerList
        )}
      </div>
      {currentAnswerSrc && (
        <audio
          ref={audioRef}
          src={`${process.env.REACT_APP_RECORDING_BASE}/${currentAnswerSrc}`}
          onEnded={() => setIsPlaying(false)}
          onError={() => {
            setIsPlaying(false);
            setInvalidAnswers((prev) => ({
              ...prev,
              [currentAnswerSrc]: true,
            }));
          }}
        />
      )}
    </div>
  );
}

export default InterviewResult;
