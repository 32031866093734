import React, { useState } from "react";

import { useHistory, Link, useLocation, Redirect } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { Input, Button, Alert } from "antd";
import { AxiosError, AxiosResponse } from "axios";
import { parse } from "query-string";

import { useAuth } from "context/authContext";
import useIsMounted from "hooks/useIsMounted";
import WaveBg from "../resources/images/wave-bg.svg";
import { resendVerificationEmail } from "services/authService";

function Login() {
  const { user, login, setUser } = useAuth();
  const history = useHistory();
  const location = useLocation();
  const parsed: { redirect?: string } = parse(location.search);
  const isMounted = useIsMounted();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string>("");
  const [emailToken, setEmailToken] = useState<string>("");
  const [resendEmailTo, setResendEmailTo] = useState<string>("");
  const { control, handleSubmit, reset } = useForm();

  if (user) {
    return <Redirect to="/jobs" />;
  }

  function onSubmit(data: Record<string, any>) {
    setIsSubmitting(true);
    setError("");
    setEmailToken("");
    login(data)
      .then((response: AxiosResponse) => {
        const user = response.data.data;
        if (isMounted.current) {
          if (user.email_verified) {
            setUser(user);
          } else {
            setEmailToken(user.email_token);
            reset();
            return;
          }
        }

        if (user.has_skills) {
          if (parsed.redirect) {
            const r = `${parsed.redirect}`;
            history.push(r);
          } else {
            history.push("/jobs");
          }
        } else {
          history.push("/resume-upload");
        }
      })
      .catch((error: AxiosError) => {
        console.log(error);
        if (isMounted.current) {
          if (error.response?.status !== 500) {
            setError(error.response?.data.message);
          } else {
            setError("Login failed.");
          }
        }
      })
      .finally(() => {
        if (isMounted.current) {
          setIsSubmitting(false);
        }
      });
  }

  function resendVerification(token: string) {
    setIsSubmitting(true);
    resendVerificationEmail(token)
      .then((response) => {
        setResendEmailTo(response.data.data.email);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  }

  return (
    <section className="">
      <div className="billboard">
        <div className="relative">
          <img src={WaveBg} alt="" />
        </div>
      </div>
      <div className="mx-auto max-w-md mt-8 px-4">
        <h1 className="text-3xl font-bold">Login</h1>
        {emailToken && (
          <div className="mb-6">
            <Alert
              type="info"
              message={
                isSubmitting
                  ? "Loading ... "
                  : `${
                      resendEmailTo
                        ? `Verification email has been sent to ${resendEmailTo}. `
                        : ""
                    }Please verify your email address by clicking the link in the verification email.`
              }
              action={
                resendEmailTo ? null : (
                  <Button
                    size="small"
                    type="link"
                    shape="round"
                    onClick={() => resendVerification(emailToken)}
                    disabled={isSubmitting}
                  >
                    Resend Email
                  </Button>
                )
              }
            />
          </div>
        )}
        {error && (
          <div className="mb-6">
            <Alert type="error" message={error} />
          </div>
        )}
        <form onSubmit={handleSubmit(onSubmit)} className="mb-4">
          <div className="mb-6">
            <Controller
              as={Input}
              name="email"
              type="email"
              control={control}
              placeholder="Email"
              defaultValue=""
              required
              disabled={isSubmitting}
              autoComplete="username"
            />
          </div>
          <div className="mb-6">
            <Controller
              as={Input}
              name="password"
              control={control}
              type="password"
              placeholder="Password"
              defaultValue=""
              required
              disabled={isSubmitting}
              autoComplete="current-password"
            />
          </div>
          <div className="flex items-center">
            <div className="flex-1">
              <Link className="" to="/register">
                Need an account?
              </Link>
              <br />
              <Link className="" to="/forgot-password">
                Forgot password?
              </Link>
            </div>
            <Button
              shape="round"
              className="w-20"
              type="primary"
              htmlType="submit"
              disabled={isSubmitting}
            >
              Login
            </Button>
          </div>
        </form>
      </div>
    </section>
  );
}

export default Login;
