import React, { useState } from "react";

import { useForm, Controller } from "react-hook-form";
import { Alert, Button, Input } from "antd";
import { Link, Redirect, useParams } from "react-router-dom";

import useIsMounted from "hooks/useIsMounted";
import { useAuth } from "context/authContext";
import { resetPassword } from "services/authService";

// function useQuery() {
//   return new URLSearchParams(useLocation().search);
// }

const passwordChanged = (
  <>
    <h1 className="text-3xl font-bold">Password Changed</h1>
    <p>
      You password has been updated. Please login in by clicking the button
      below.
    </p>
    <Link to="/login">
      <Button type="primary" shape="round">
        Login
      </Button>
    </Link>
  </>
);

function ResetPassword() {
  const isMounted = useIsMounted();
  const { user } = useAuth();
  // const query = useQuery();
  // const token = query.get("token");
  const { x, token } = useParams<{ x: string; token: string }>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [message, setMessage] = useState("");
  const { control, handleSubmit, getValues, errors } = useForm();

  function onSubmit(data: Record<string, string>) {
    setMessage("");
    setIsSubmitting(true);
    resetPassword(x, token, { newPassword: data.confirm_password })
      .then((response) => {
        setIsSuccessful(true);
      })
      .catch((error) => {
        console.log(error);
        if (isMounted.current) {
          setMessage("Something went wrong. Please try again later.");
          setIsSuccessful(false);
        }
      })
      .finally(() => {
        if (isMounted.current) {
          setIsSubmitting(false);
        }
      });
  }

  // TODO: This is too basic, look into libraries for FE/BE password strength
  function validateStrength(s: string) {
    if (!s) {
      return "Please enter a password";
    }

    if (s.length < 8) {
      return "Password has to be longer than 8 characters";
    } else if (s.length > 20) {
      return "Password cannot be longer than 20 characters";
    }

    return true;
  }

  if (user) {
    return <Redirect to="/settings" />;
  }

  if (!token) {
    return (
      <section>
        <div className="mx-auto max-w-md mt-8 px-4">
          <h1 className="text-3xl font-bold">Invalid Token</h1>
          {/* <p className="p-4 bg-white shadow-md">Please click the link from the email to create new password.</p> */}
          <Alert
            type="error"
            message={
              <>
                Please click the link from the email to create new password.{" "}
                <Link to="/forgot-password">Resend link</Link>
              </>
            }
          />
        </div>
      </section>
    );
  }

  return (
    <section>
      <div className="mx-auto max-w-md mt-8">
        {isSuccessful ? (
          passwordChanged
        ) : (
          <>
            <h1 className="text-3xl font-bold">Create new password</h1>
            {message && (
              <div className="mb-4">
                <Alert type="error" message={message} />
              </div>
            )}

            <form className="mb-4" onSubmit={handleSubmit(onSubmit)}>
              <div className="mb-2">
                <Controller
                  as={Input.Password}
                  name="password"
                  type="password"
                  control={control}
                  rules={{
                    required: "Please specify a password",
                    validate: validateStrength,
                  }}
                  placeholder="Password"
                  required
                  disabled={isSubmitting}
                  className="mb-2"
                />
                {errors.password && (
                  <p className="text-red-500">{errors.password.message}</p>
                )}
              </div>
              <div className="mb-6">
                <Controller
                  as={Input.Password}
                  name="confirm_password"
                  type="password"
                  control={control}
                  rules={{
                    required: "Please specify a password",
                    validate: (value) => {
                      if (value === getValues()["password"]) {
                        return true;
                      } else {
                        return "Both passwords must match";
                      }
                    },
                  }}
                  className="mb-2"
                  placeholder="Confirm Password"
                  required
                  disabled={isSubmitting}
                />
                {errors.confirm_password && (
                  <p className="text-red-500">
                    {errors.confirm_password.message}
                  </p>
                )}
              </div>
              <Button
                shape="round"
                type="primary"
                className="flex"
                htmlType="submit"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Loading ..." : "Reset Password"}
              </Button>
            </form>
          </>
        )}
      </div>
    </section>
  );
}

export default ResetPassword;
