import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { Button, message, Skeleton } from "antd";
import { DollarCircleFilled, CheckCircleFilled } from "@ant-design/icons";
import { useAuth } from "context/authContext";

import * as jobService from "services/jobService";
import * as userService from "services/userService";
import useIsMounted from "hooks/useIsMounted";


// const MOCK_JOBS = [
//   {
//     id: 1,
//     title: "Software Engineer I",
//     companyName: "Company A",
//     description:
//       "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus quia, nulla! Maiores et perferendis eaque, exercitationem praesentium nihil.",
//     salaryLow: 80,
//     salaryHigh: 100,
//     skills: ["Javascript", "CSS", "Angular", "NodeJS"],
//     enabled: true,
//   },
//   {
//     id: 2,
//     title: "Software Engineer II",
//     companyName: "Company B",
//     description:
//       "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus quia, nulla! Maiores et perferendis eaque, exercitationem praesentium nihil.",
//     salaryLow: 80,
//     salaryHigh: 100,
//     skills: ["Javascript", "CSS", "Angular", "NodeJS"],
//     enabled: true,
//   },
//   {
//     id: 3,
//     title: "Software Engineer I",
//     companyName: "Company C",
//     description:
//       "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus quia, nulla! Maiores et perferendis eaque, exercitationem praesentium nihil.",
//     salaryLow: 80,
//     salaryHigh: 100,
//     skills: ["Javascript", "CSS", "Angular", "NodeJS"],
//     enabled: true,
//   },
//   {
//     id: 4,
//     title: "Software Engineer III",
//     companyName: "Company D",
//     description:
//       "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus quia, nulla! Maiores et perferendis eaque, exercitationem praesentium nihil.",
//     salaryLow: 80,
//     salaryHigh: 100,
//     skills: ["Javascript", "CSS", "Angular", "NodeJS"],
//     enabled: false,
//   },
//   {
//     id: 5,
//     title: "Software Engineer IV",
//     companyName: "Company E",
//     description:
//       "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus quia, nulla! Maiores et perferendis eaque, exercitationem praesentium nihil.",
//     salaryLow: 80,
//     salaryHigh: 100,
//     skills: ["Javascript", "CSS", "Angular", "NodeJS"],
//     enabled: false,
//   },
//   {
//     id: 6,
//     title: "Software Engineer IV",
//     companyName: "Company F",
//     description:
//       "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus quia, nulla! Maiores et perferendis eaque, exercitationem praesentium nihil.",
//     salaryLow: 80,
//     salaryHigh: 100,
//     skills: ["Javascript", "CSS", "Angular", "NodeJS"],
//     enabled: false,
//   },
//   {
//     id: 7,
//     title: "Software Engineer III",
//     companyName: "Company D",
//     description:
//       "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus quia, nulla! Maiores et perferendis eaque, exercitationem praesentium nihil.",
//     salaryLow: 80,
//     salaryHigh: 100,
//     skills: ["Javascript", "CSS", "Angular", "NodeJS"],
//     enabled: false,
//   },
//   {
//     id: 8,
//     title: "Software Engineer IV",
//     companyName: "Company E",
//     description:
//       "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus quia, nulla! Maiores et perferendis eaque, exercitationem praesentium nihil.",
//     salaryLow: 80,
//     salaryHigh: 100,
//     skills: ["Javascript", "CSS", "Angular", "NodeJS"],
//     enabled: false,
//   },
//   {
//     id: 9,
//     title: "Software Engineer IV",
//     companyName: "Company F",
//     description:
//       "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus quia, nulla! Maiores et perferendis eaque, exercitationem praesentium nihil.",
//     salaryLow: 80,
//     salaryHigh: 100,
//     skills: ["Javascript", "CSS", "Angular", "NodeJS"],
//     enabled: false,
//   },
// ];

type MockJob = {
  id: number;
  title: string;
  companyName: string;
  description: string;
  salaryLow: number;
  salaryHigh: number;
  skills: string[];
  enabled: boolean;
};

function Jobs() {
  // Todo: enabled is true if the user is a paid subscriber or if they are on the trial interviews
  const isMounted = useIsMounted();
  const [loading, setLoading] = useState(true);
  const [jobs, setJobs] = useState<MockJob[] | any>([]); // TODO: Need to make MockJob accept a different attribute to support email notification card or Ads in the future
  const [notifyNewQuestion, setNotifyNewQuestion] = useState<boolean>(false);
  const {user} = useAuth();

  const loadingCards = [1, 2, 3].map((item, index) => (
    <div
      key={index}
      className={`job-item-container max-w-xs rounded overflow-hidden shadow-lg inline-block mr-4 mb-4 bg-white w-1/5`}
    >
      <div className="h-48 bg-gray-300"></div>
      <div className="px-6 py-4">
        <Skeleton active />
      </div>
    </div>
  ));

  const generateSkillsText = (skills: string[]) =>
    skills.length > 0 &&
    `Looking for candidates with experience in ${skills
      .slice(0, skills.length - 1)
      .join(", ")}, and ${skills[skills.length - 1]}.`;

  const randomImage = () => {
    return "/office-" + (Math.floor(Math.random() * 12) + 1) + ".jpg";
  } 

  const signUpForNewQuestionNotification = async () => {
    try {
      setNotifyNewQuestion(!notifyNewQuestion);
      await userService.updateUserPreference({
        notify_new_question: !notifyNewQuestion,
      });
    } catch (error) {
      message.error("Submit failed. Please try again.");
    }
  }

  const jobList = jobs.map((job: any, index: number) => {
    return (
      <div
        key={index}
        className={
          `job-item-container max-w-xs rounded overflow-hidden shadow-lg inline-block mr-4 mb-4 bg-white enabled`
        }
      >
        {job.cardType === "notify_new_question" ? (
          <>
            <img className="w-full" src="/mail.jpg" alt="Notify Me" />
            <div className="px-6 py-4">
              <div className="font-bold text-lg truncate" title="Sign up for email notification">
                Email Notification
              </div>
              <p className="text-gray-500 text-basemt-2 mb-4">Promoted</p>
              <div className="h-20 overflow-auto">
                <p className="text-gray-700 text-basemt-2">Subscribe to our email notification to receive updates on new mock questions and features :)</p>
              </div>
            </div>
            <div className="px-4 py-2 flex justify-between border-t">
              <span className="py-1"> 
              {notifyNewQuestion ? 
                <>
                  <CheckCircleFilled
                    style={{
                      fontSize: "18px",
                      color: "#38a169",
                      verticalAlign: "unset",
                    }}
                  />{" "}
                  Subscribed
                </> :
                ""}
              </span>
              <Button type="primary" shape="round" style={{ background: "#e6b800", borderColor: "#deb300 " }} onClick={() => signUpForNewQuestionNotification()}>
                {notifyNewQuestion ? "Unsubscribe" : "Subscribe"}
              </Button>
            </div>
          </>
        ) : (
          <>
            <img className="w-full" src={randomImage()} alt={job.companyName} />
            <div className="px-6 py-4">
              <div className="font-bold text-lg truncate" title={job.title}>
                {job.title}
              </div>
              <p className="text-gray-500 text-basemt-2 mb-4">{job.companyName}</p>
              <div className="h-20 overflow-auto">
                <p className={"text-gray-700 text-basemt-2 " + (job.description ? "" : "hidden" )}>{job.description}</p>
                <p>{generateSkillsText(job.skills)}</p>
              </div>
            </div>
            <div className="px-4 py-2 flex justify-between border-t">
              <span className="py-1">
                <DollarCircleFilled
                  style={{
                    fontSize: "18px",
                    color: "#ffcc00",
                    verticalAlign: "unset",
                  }}
                />{" "}
                ${job.salaryLow}k-${job.salaryHigh}k
              </span>
              <Button type="primary" shape="round" className="">
                <Link to={"/waitingroom/" + job.id}>Start Now</Link>
              </Button>
            </div>
          </>
        )}
        
      </div>
    );
  });

  useEffect(() => {
    jobService
      .getMockJobs()
      .then((response) => {
        if (isMounted.current) { 
          const cards = response.data.data;

          // Insert a click to sign up for email notification when more questions are available
          cards.splice(3, 0, {
            cardType: "notify_new_question",
          });
          if (user) {
            setNotifyNewQuestion(user.notify_new_question);
          }
          setJobs(cards);
        }
      })
      .catch((error) => {
        if (isMounted.current) {
          setLoading(false);
        }
      })
      .finally(() => {
        if (isMounted.current) {
          setLoading(false);
        }
      });
  }, [isMounted, user]);

  return (
    <div className="jobs-list">
      <div className="header-container">
        <h1 className="text-2xl font-semibold">Mock Jobs</h1>
      </div>
      {loading ? (
        <div>{loadingCards}</div>
      ) : (
        <>
          <div className="job-container">
            {jobs.length === 0 ? <p>No jobs. :(</p> : jobList}
          </div>
        </>
      )}
    </div>
  );
}

export default Jobs;
