import * as authService from "services/authService";

function getUser(): Promise<any> {
  return authService.getUser();
}

function login(data: Record<string, any>) {
  return authService.login(data);
}

function register(data: Record<string, any>) {
  return authService.register(data);
}

function logout() {
  return authService.logout()
}

export { getUser, login, register, logout };
