import React, { useState, useEffect } from "react";

import { useForm, Controller } from "react-hook-form";
import { Button, Spin } from "antd";
import { DeleteOutlined, LoadingOutlined } from "@ant-design/icons";

import SkillSelector, { Skill } from "components/SkillsSelector";

type FormProps = {
  initialValues: Array<{ id: string; name: string }>;
  submit: (data: any) => void;
};

function Form(props: FormProps) {
  const [skills, setSkills] = useState<typeof props.initialValues>([]);
  const { handleSubmit, formState, control } = useForm();
  const { isSubmitting } = formState;
  const [errorMessage, setErrorMessage] = useState("");

  function addSkill(newSkill: Skill) {
    setErrorMessage("");
    const foundDuplicate = skills.find((item) => item.id === newSkill.id);
    if (foundDuplicate) {
      setErrorMessage(
        `${newSkill.name} already exists. Please enter another skill.`
      );
    } else {
      setSkills((prevSkills) => [
        ...prevSkills,
        { id: newSkill.id, name: newSkill.name },
      ]);
    }
  }

  useEffect(() => {
    setSkills(props.initialValues);
  }, [props.initialValues]);

  function removeSkill(id: string) {
    setSkills((prevSkills) => prevSkills.filter((skill) => skill.id !== id));
  }

  return (
    <form onSubmit={handleSubmit(props.submit)}>
      <div className="w-full lg:w-2/5 ">
        <Spin spinning={isSubmitting} indicator={<LoadingOutlined />}>
          {skills.map((skill, index) => (
            <div key={skill.id} className="group flex items-center mb-3">
              <Controller
                as={
                  <div className="bg-white p-2 rounded-lg flex-grow shadow-md">
                    {skill.name}
                  </div>
                }
                name={`skill-${skill.id}`}
                control={control}
                defaultValue={{ id: skill.id }}
              />
              <button
                className={`invisible p-2 ${
                  !isSubmitting && "group-hover:visible hover:text-red-500"
                }`}
                onClick={() => removeSkill(skill.id)}
                disabled={isSubmitting}
              >
                <DeleteOutlined className="text-xl" />
              </button>
            </div>
          ))}

          <div className="flex mb-2">
            <SkillSelector
              formatOptionLabel={(value) => {
                return Array.isArray(value) ? value : value?.name;
              }}
              formatOptionValue={(value) => {
                return Array.isArray(value) ? value : value?.id;
              }}
              onChange={(value: Skill | undefined) => {
                if (value) {
                  addSkill(value);
                }
              }}
              disabled={isSubmitting}
            />
            <div className="invisible" style={{ width: 36 }}></div>
          </div>
          {errorMessage && <p className="p-2 text-red-600">{errorMessage}</p>}
        </Spin>
      </div>
      <div className="mt-8">
        <Button type="primary" shape="round" htmlType="submit" loading={isSubmitting}>
          Done
        </Button>
      </div>
    </form>
  );
}

export default Form;
