import { API } from "utils/axiosInstance";

function search(skill: string) {
  return API.get("/skills", {
    params: {
      search: skill,
    },
  });
}

export { search };
