import React, { ReactNode } from "react";

import { Link, useLocation, useHistory } from "react-router-dom";
import { Avatar, Dropdown, Layout, Menu } from "antd";
import {
  SolutionOutlined,
  LaptopOutlined,
  IdcardOutlined,
} from "@ant-design/icons";
import { AxiosError, AxiosResponse } from "axios";

import JobPrepperLogo from "../resources/images/jobprepper_white.svg";
import { useAuth } from "context/authContext";
import * as browserService from "services/browserService";

const { Header, Content, Footer, Sider } = Layout;

type PublicLayoutProps = {
  children: ReactNode;
};

function PublicLayout({ children }: PublicLayoutProps) {
  const { user, isLoading } = useAuth();
  const { pathname } = useLocation();
  const currentYear = new Date().getFullYear();
  const isBrowserSupported = browserService.isSpeechSupported();

  return (
    <Layout style={{ minHeight: "100vh" }}>
      { isBrowserSupported ? <></> : <div className="p-2 bg-yellow-200 text-center">Your browser is not supported. We recommend using Chrome version 70 or above.</div> }
      <Header className="flex">
        <div className="flex-1 flex">
          <Link to="/" className="inline-block w-32">
            <img src={JobPrepperLogo} alt="Logo" className="inline-block h-8" />
            <span className="inline-block ml-3 font-semibold text-white">
              JobPrepper
            </span>
          </Link>          
          <Menu
            theme="dark"
            mode="horizontal"
            className="flex-1"
            selectedKeys={[pathname]}
          >
            <Menu.Item key="/jobs">
              <Link to="/jobs">Mock Jobs</Link>
            </Menu.Item>
            <Menu.Item key="/contact">
              <Link to="/contact">Contacts</Link>
            </Menu.Item>
          </Menu>
        </div>
        <div>
          {isLoading ? null : !user ? (
            <>
              <Link
                to="/login"
                className="rounded-full cursor-pointer text-white mr-4"
              >
                Login
              </Link>
              <Link
                to="/register"
                className="bg-white hover:bg-blue-100 px-4 py-2 text-blue-600 hover:text-blue-500 font-bold rounded-full cursor-pointer shadow-md"
              >
                Sign Up
              </Link>
            </>
          ) : (
            <Link
              to="/jobs"
              className="bg-white hover:bg-blue-100 px-4 py-2 text-blue-600 hover:text-blue-500 font-bold rounded-full cursor-pointer shadow-md"
            >
              Open App
            </Link>
          )}
        </div>
      </Header>
      <Content>{children}</Content>
      <Footer className="text-center">JobPrepper &copy; {currentYear}</Footer>
    </Layout>
  );
}

type PrivateLayoutProps = {
  children: ReactNode;
};

function PrivateLayout({ children }: PrivateLayoutProps) {
  const { user, logout, setUser } = useAuth();
  const history = useHistory();
  const location = useLocation();
  const userInitials = `${user?.first_name[0]}${user?.last_name[0]}` || "";
  const userMenu = (
    <Menu>
      <Menu.Item key="2" onClick={logoutUser}>
        Logout
      </Menu.Item>
    </Menu>
  );

  function logoutUser() {
    logout()
      .then((response: AxiosResponse) => {
        setUser(undefined);
        history.push("/login");
      })
      .catch((error: AxiosError) => {
        console.log(error);
      });
  }

  function navigateTo(route: string) {
    history.push(route);
  }

  return (
    <Layout style={{ minHeight: "100vh" }}>
      {/* This is copied and pasted from https://ant.design/components/layout/ */}
      <Header
        className="header flex items-center"
        style={{ padding: "0 12px" }}
      >
        <div className="logo-container">
          <img
            src={JobPrepperLogo}
            alt="Logo"
            className="inline-block h-8 pl-4 pr-6"
          />
        </div>

        <div className="menu-container flex flex-grow justify-between">
          <Menu
            theme="dark"
            mode="horizontal"
            defaultSelectedKeys={[location.pathname]}
          >
            <Menu.Item key="/jobs">
              <Link to="/jobs">Interview</Link>
            </Menu.Item>
            <Menu.Item key="/contact">
              <Link to="/contact">Contact</Link>
            </Menu.Item>
          </Menu>
          <Dropdown overlay={userMenu}>
            <button className="text-white" onClick={(e) => e.preventDefault()}>
              <span className="mr-2">
                <Avatar>{userInitials}</Avatar>
              </span>{" "}
              {`${user?.first_name}`}
            </button>
          </Dropdown>
        </div>
      </Header>
      <Layout>
        <Sider width={200} className="site-layout-background">
          {user?.has_skills ? (
            <Menu
              mode="inline"
              defaultSelectedKeys={["1"]}
              defaultOpenKeys={["sub1"]}
              style={{ height: "100%", borderRight: 0 }}
            >
              <Menu.Item
                key="1"
                icon={<SolutionOutlined />}
                onClick={() => navigateTo("/jobs")}
              >
                <span>Interview</span>
              </Menu.Item>
              <Menu.Item
                key="2"
                icon={<LaptopOutlined />}
                onClick={() => navigateTo("/results")}
              >
                <span>Results</span>
              </Menu.Item>
            </Menu>
          ) : (
            <Menu
              mode="inline"
              defaultSelectedKeys={["1"]}
              defaultOpenKeys={["sub1"]}
              style={{ height: "100%", borderRight: 0 }}
            >
              <Menu.Item key="3" icon={<IdcardOutlined />}>
                Onboarding
              </Menu.Item>
            </Menu>
          )}
        </Sider>
        <Layout style={{ padding: "0 24px 24px" }}>
          {/* <Breadcrumb style={{ margin: "16px 0" }}>
            <Breadcrumb.Item>Home</Breadcrumb.Item>
            <Breadcrumb.Item>List</Breadcrumb.Item>
            <Breadcrumb.Item>App</Breadcrumb.Item>
          </Breadcrumb> */}
          <Content
            className="site-layout-background"
            style={{
              padding: 24,
              margin: 0,
              minHeight: 280,
            }}
          >
            {children}
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
}

export { PublicLayout, PrivateLayout };
