import React from "react";

import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

function FullPageSpinner() {
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  return (
    <div className="w-screen h-screen flex justify-center items-center">
      <Spin indicator={antIcon} />
    </div>
  );
}

export default FullPageSpinner;
