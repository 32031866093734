import React, { useState } from "react";
import { Input, Button, Row, Col, Alert } from "antd";
import { useAuth } from "context/authContext";
import { useForm, Controller } from "react-hook-form";
import { Link } from "react-router-dom";
import { AxiosError } from "axios";

type FormProps = React.HTMLAttributes<HTMLFormElement> & {
  onSuccess: () => void;
};

function Form(props: FormProps) {
  const { user, register } = useAuth();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string>("");
  const { control, handleSubmit } = useForm();

  if (user) {
    window.location.replace(window.location.origin);
  }

  function onSubmit(data: Record<string, any>) {
    setError("");
    setIsSubmitting(true);
    register(data)
      .then((response: Response) => {
        if (response.status === 201) {
          props.onSuccess();
        }
      })
      .catch((error: AxiosError) => {
        if (error.response) {
          if (error.response.status < 500) {
            setError(error.response.data.message);
          } else {
            setError("Sign up failed. Please try again later.");
          }
        }
        setIsSubmitting(false);
      });
  }

  return (
    <>
      {error && (
        <div className="mb-6">
          <Alert type="error" message={error} />
        </div>
      )}
      <form onSubmit={handleSubmit(onSubmit)} {...props}>
        <Row gutter={24}>
          <Col md={12}>
            <Controller
              as={Input}
              name="firstName"
              control={control}
              placeholder="First Name"
              required
              disabled={isSubmitting}
            />
          </Col>
          <Col md={12}>
            <Controller
              as={Input}
              name="lastName"
              control={control}
              placeholder="Last Name"
              required
              disabled={isSubmitting}
            />
          </Col>
        </Row>
        <div className="my-6">
          <Controller
            as={Input}
            name="email"
            control={control}
            placeholder="email"
            required
            disabled={isSubmitting}
          />
        </div>
        <div className="my-6">
          <Controller
            as={Input}
            name="password"
            control={control}
            type="password"
            placeholder="Password"
            required
            disabled={isSubmitting}
          />
        </div>
        <div className="flex">
          <Link className="flex-1 mt-1" to="/login">
            Already have an account?
          </Link>
          <Button
            shape="round"
            type="primary"
            htmlType="submit"
            disabled={isSubmitting}
          >
            Register
          </Button>
        </div>
      </form>
    </>
  );
}

export default Form;
