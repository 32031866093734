import { API } from "utils/axiosInstance";

function getMockJobs() {
  return API.get("/jobs");
}

function getMockJob(id: number) {
  return API.get(`/jobs/${id}`);
}

export { getMockJobs, getMockJob };
