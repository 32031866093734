import _ from "lodash";

const defaultVoiceName = "Google US English";
const synth = window.speechSynthesis;

function speak(args: any) {
  let { voice = null, text = '', rate = 1, pitch = 1, handleEnd = null } = args;
  
  if (!synth) {
    return false; // No browser support
  }

  if (!voice) {
    const voices = synth.getVoices();
    voice = _.find(voices, (voice) => {
      return voice.name === defaultVoiceName;
    })
  }
 
  const message = new window.SpeechSynthesisUtterance();
  message.text = text;
  message.voice = voice;
  message.onend = handleEnd; // When the speech is done, we want to start recording
  message.rate = rate;
  message.pitch = pitch;
  message.lang = "en-US";
  synth.speak(message);

  return message;
}

function stop() {
  synth.cancel();
}

export { speak, stop };
