import React, { useState } from "react";

import { useForm, Controller } from "react-hook-form";
import { Alert, Input, Button, Radio, Form as AntDForm } from "antd";

import useIsMounted from "hooks/useIsMounted";
import supportService, { ContactMessage } from "services/supportService";
import { useAuth } from "context/authContext";
import Recaptcha from "react-recaptcha";

type FormData = {
  name: string;
  email: string;
  message: string;
  reason: string;
};

type FormMode = "form" | "success";

const formItemLayout = {
  labelAlign: "left" as const,
  labelCol: {
    xs: { span: 24 },
  },
  wrapperCol: {
    xs: { span: 24 },
  },
};

function ContactUs() {
  const isMounted = useIsMounted();
  const { user } = useAuth();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isCaptchaApproved, setIsCaptchaApproved] = useState(false);
  const [mode, setMode] = useState<FormMode>("form");
  const [message, setMessage] = useState<string>();
  const { control, handleSubmit, errors, reset } = useForm<FormData>({
    defaultValues: {
      name: user ? `${user.first_name} ${user.last_name}` : "",
      email: user ? user?.email : "",
      message: "",
      reason: "",
    },
  });

  const captchaKey = process.env.REACT_APP_CAPTCHA;

  function onSubmit(values: ContactMessage) {
    if (isCaptchaApproved) {
      setIsSubmitting(true);
      setMessage("");
      setMode("form");

      supportService
        .sendMessage(values)
        .then((response) => {
          if (isMounted.current) {
            setMessage(response.data.data);
            setMode("success");
          }
        })
        .catch((error) => {
          console.log(error);
          setMessage("Something went wrong. Please try again later.");
          setMode("form");
        })
        .finally(() => {
          if (isMounted.current) {
            setIsSubmitting(false);
            reset();
            window.scrollTo(0, 0);
          }
        });
    } else {
      setMessage(
        "Please verify that you are human by clicking on the captcha checkbox."
      );
    }
  }

  function handleCaptcha(token: string) {
    if (token) {
      setIsCaptchaApproved(true);
    }
  }

  function handleExpiredCallback() {
    setIsCaptchaApproved(false);
  }

  return (
    <section>
      <div className="mx-auto max-w-md mt-8 px-4">
        <h1 className="text-3xl font-bold">Contact Us</h1>
        {message && (
          <div className="mb-4">
            <Alert
              type={mode === "success" ? "success" : "error"}
              message={message}
            />
          </div>
        )}
        <AntDForm {...formItemLayout} onSubmitCapture={handleSubmit(onSubmit)}>
          <div className="mb-6">
            <Controller
              name="name"
              control={control}
              required
              defaultValue=""
              rules={{ required: { value: true, message: "Required" } }}
              render={(props) => (
                <AntDForm.Item
                  label="Name"
                  validateStatus={errors.name && "error"}
                  help={errors.name && errors.name.message}
                >
                  <Input
                    {...props}
                    type="text"
                    placeholder="Your name"
                    disabled={isSubmitting}
                    required
                  />
                </AntDForm.Item>
              )}
            />
          </div>
          <div className="mb-6">
            <Controller
              name="email"
              control={control}
              required
              defaultValue=""
              rules={{ required: { value: true, message: "Required" } }}
              render={(props) => (
                <AntDForm.Item
                  label="Email"
                  validateStatus={errors.email && "error"}
                  help={errors.email && errors.email.message}
                >
                  <Input
                    type="email"
                    placeholder="Email address"
                    {...props}
                    disabled={isSubmitting}
                    required
                  />
                </AntDForm.Item>
              )}
            />
          </div>
          <div className="mb-6">
            <Controller
              name="message"
              control={control}
              defaultValue=""
              rules={{ required: { value: true, message: "Required" } }}
              render={(props) => (
                <AntDForm.Item
                  label="Message"
                  validateStatus={errors.message && "error"}
                  help={errors.message && errors.message.message}
                >
                  <Input.TextArea
                    rows={5}
                    placeholder="Message"
                    {...props}
                    disabled={isSubmitting}
                    required
                  />
                </AntDForm.Item>
              )}
            />
          </div>
          <div className="mb-6">
            <Controller
              name="reason"
              control={control}
              defaultValue=""
              rules={{ required: { value: true, message: "Required" } }}
              render={({ onChange, ...props }) => (
                <AntDForm.Item
                  label="Reason"
                  validateStatus={errors.reason && "error"}
                  help={errors.reason && errors.reason.message}
                >
                  <Radio.Group
                    buttonStyle="solid"
                    onChange={(e) => onChange(e.target.value)}
                    defaultValue=""
                    disabled={isSubmitting}
                    {...props}
                  >
                    <Radio.Button value="feedback">Feedback</Radio.Button>
                    <Radio.Button value="technical">
                      Technical Issues
                    </Radio.Button>
                  </Radio.Group>
                </AntDForm.Item>
              )}
            />
          </div>
          <div className="mb-6">
            <Recaptcha
              sitekey={captchaKey}
              render="explicit"
              verifyCallback={handleCaptcha}
              expiredCallback={handleExpiredCallback}
            />
          </div>
          <Button
            shape="round"
            type="primary"
            htmlType="submit"
            disabled={isSubmitting}
          >
            {isSubmitting ? "Submitting ..." : "Submit"}
          </Button>
        </AntDForm>
      </div>
    </section>
  );
}

export default ContactUs;
